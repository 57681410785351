import React from 'react';

import { Box, Container, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import FaqBlockComponent from './block-component';

interface IFaqBodyComponent {
  header: string;
  updatedAt: string;
  linkPrevHref?: string;
  linkNextHref?: string;
  children?: React.ReactElement;
}

const FaqBodyComponent: React.FC<IFaqBodyComponent> = ({ header, updatedAt, linkPrevHref, linkNextHref, children }) => {
  return (
    <Container component="main" maxWidth="md">
      <Box
        sx={{
          marginTop: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginBottom: 2,
        }}
      >
        <Typography component="h1" variant="h5" sx={{ mt: 1, fontSize: 18 }}>
          {header}
        </Typography>
        <Typography component="h1" variant="h5" sx={{ mt: 1, fontSize: 14 }}>
          Дата последнего обновления: {updatedAt}
        </Typography>
        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', mt: 4, gap: 2 }}>{children}</Box>
        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', mt: 4, gap: 2 }}>
          {linkPrevHref && (
            <Link to={linkPrevHref} style={{ textDecoration: 'none', color: '#333', fontSize: 18 }}>
              Предыдущая
            </Link>
          )}
          {linkNextHref && (
            <Link to={linkNextHref} style={{ textDecoration: 'none', color: '#333', fontSize: 18 }}>
              Следующая
            </Link>
          )}
        </Box>
      </Box>
    </Container>
  );
};

export default FaqBodyComponent;
