import React from 'react';

import { Accordion, AccordionDetails, AccordionSummary, Box, Container, Typography } from '@mui/material';

import FaqBlockComponent from '../components/faq/block-component';
import FaqBodyComponent from '../components/faq/body-component';

export const FAQComponent = () => {
  React.useEffect(() => {
    document.title = 'Инструкции | КлинКар';
  }, []);

  return (
    <Container component="main" maxWidth="md">
      <Box
        sx={{
          marginTop: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginBottom: 10,
        }}
      >
        <Typography component="h1" variant="h5" sx={{ mt: 1 }}>
          Инструкции для приложения
        </Typography>
        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', mt: 4, gap: 2 }}>
          <FaqBlockComponent
            header={'На каких устройствах приложение работает быстрее'}
            body={
              'На некоторых смартфонах и планшетах приложение работает быстрее всего — рассказываем, как выбрать оптимальное устройство.'
            }
            linkHref={'devices/'}
          />
          <FaqBlockComponent
            header={'Как настроить телефон для оптимальной работы приложения'}
            body={
              'Иногда можно обойтись без смены устройства — достаточно правильно настроить приложение и дать ему доступ к необходимым функциям.'
            }
            linkHref={'settings/'}
          />
          <FaqBlockComponent
            header={'Зачем приложению доступ к функциям смартфона'}
            body={
              'Это необходимо для удобной и безопасной работы, а без доступа к некоторым функциям поездки будут недоступны.'
            }
            linkHref={'functions/'}
          />
          <FaqBlockComponent
            header={'Как улучшить точность геолокации'}
            body={
              'Если выключить ограничение запросов Wi-Fi в настройках смартфона, местоположение в Москве будет определяться точнее.'
            }
            linkHref={'geolocations/'}
          />
        </Box>
      </Box>
    </Container>
  );
};

export const FAQDevicesComponent = () => {
  React.useEffect(() => {
    document.title = 'Инструкции | КлинКар';
  }, []);

  return (
    <FaqBodyComponent
      header={'На каких устройствах приложение работает быстрее'}
      updatedAt={'04.10.2024'}
      linkNextHref={'/faq/settings/'}
    >
      <>
        <Typography component="h5" variant="h5" sx={{ fontSize: 16 }}>
          На медленных смартфонах и планшетах приложение может неточно определять, где вы находитесь, зависать или
          выдавать ошибки. Чтобы у вас меньше времени уходило на проблемы, связанные с устройством, мы рекомендуем
          <b> Android:</b>
        </Typography>
        <ul>
          <li>
            <Typography component="h5" variant="h5" sx={{ fontSize: 16 }}>
              <b>с оперативной памятью не менее 2 Гб (лучше 4 и выше)</b> — от этого зависит скорость работы приложений.
            </Typography>
          </li>
          <li>
            <Typography component="h5" variant="h5" sx={{ mt: 2, fontSize: 16 }}>
              <b>с GPS и ГЛОНАСС</b> — включите в настройках режим максимальной точности и используйте Wi-Fi, чтобы ваше
              местоположение определялось точнее.
            </Typography>
          </li>
          <li>
            <Typography component="h5" variant="h5" sx={{ mt: 2, fontSize: 16 }}>
              <b>с версией Android не ниже 10.0</b> — для лучшей работы пользуйтесь самой последней версией.
            </Typography>
          </li>
        </ul>
      </>
    </FaqBodyComponent>
  );
};

export const FAQSettingsComponent = () => {
  React.useEffect(() => {
    document.title = 'Инструкции | КлинКар';
  }, []);

  const images = [
    {
      original:
        'https://www.google.com/url?sa=i&url=https%3A%2F%2Fmimigram.ru%2Fblog%2Fchto-takoe-foto-tekhnologiya-ili-iskusstvo%2F&psig=AOvVaw1OenwFzqJigZNQN8CF_cGR&ust=1728152538381000&source=images&cd=vfe&opi=89978449&ved=0CBQQjRxqFwoTCPDzpIOs9YgDFQAAAAAdAAAAABAE',
      thumbnail:
        'https://www.google.com/url?sa=i&url=https%3A%2F%2Fmimigram.ru%2Fblog%2Fchto-takoe-foto-tekhnologiya-ili-iskusstvo%2F&psig=AOvVaw1OenwFzqJigZNQN8CF_cGR&ust=1728152538381000&source=images&cd=vfe&opi=89978449&ved=0CBQQjRxqFwoTCPDzpIOs9YgDFQAAAAAdAAAAABAE',
    },
    {
      original: 'https://picsum.photos/id/1015/1000/600/',
      thumbnail: 'https://picsum.photos/id/1015/250/150/',
    },
    {
      original: 'https://picsum.photos/id/1019/1000/600/',
      thumbnail: 'https://picsum.photos/id/1019/250/150/',
    },
  ];

  return (
    <FaqBodyComponent
      header={'Как настроить телефон для оптимальной работы приложения'}
      updatedAt={'04.10.2024'}
      linkNextHref={'/faq/functions/'}
      linkPrevHref={'/faq/devices/'}
    >
      <>
        <Typography component="h5" variant="h5" sx={{ fontSize: 16 }}>
          <b>Как найти любую настройку в телефоне</b>
        </Typography>
        <Typography component="h5" variant="h5" sx={{ fontSize: 16 }}>
          Если у вас не получается найти какую-то настройку или пункт меню, просто наберите в поиске телефона нужное вам
          слово — обычно в настройках телефона есть строка поиска. Например, чтобы найти пункт, связанный с определением
          местоположения, вы можете набрать: «геолокация», «локация», «местоположение» или «GPS». Ниже вы найдёте
          инструкции по настройками для самых распространённых моделей. Если для вашей модели нет отдельной инструкции,
          мы оставили подсказки, где искать нужный пункт.
        </Typography>
        <Accordion>
          <AccordionSummary aria-controls="panel1-content" id="panel1-header">
            <Typography>
              <b>Как отключить режим энергосбережения</b>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography component="h5" variant="h5" sx={{ fontSize: 16 }}>
              <b>Если у вас другое устройство</b>
            </Typography>
            <Typography component="h5" variant="h5" sx={{ fontSize: 16 }}>
              Попробуйте поискать в «Настройках» пункты «Аккумулятор» или «Батарея».
              <br />
              Что можно вбить в поиск: энергосбережение, батарея, аккумулятор, питание.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary aria-controls="panel2-content" id="panel2-header">
            <Typography>
              <b>Как включить автообновление</b>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>Цепочка действий будет одинаковой для любого устройства на Андроиде:</Typography>
            <ul>
              <li>
                <Typography component="h5" variant="h5" sx={{ fontSize: 16 }}>
                  Зайдите в приложение «Play Маркет» — его иконка должна быть на одном из экранов вашего телефона.
                </Typography>
              </li>
              <li>
                <Typography component="h5" variant="h5" sx={{ mt: 1, fontSize: 16 }}>
                  В левом верхнем углу нажмите на кнопку меню.
                </Typography>
              </li>
              <li>
                <Typography component="h5" variant="h5" sx={{ mt: 1, fontSize: 16 }}>
                  Зайдите в «Настройки».
                </Typography>
              </li>
              <li>
                <Typography component="h5" variant="h5" sx={{ mt: 1, fontSize: 16 }}>
                  Нажмите на «Автообновление приложений».
                </Typography>
              </li>
              <li>
                <Typography component="h5" variant="h5" sx={{ mt: 1, fontSize: 16 }}>
                  Выберите вариант «Любая сеть».
                </Typography>
              </li>
            </ul>
            <Typography>
              Теперь телефон будет автоматически обновляться, когда вы ставите его на зарядку. Чтобы загружать
              обновления можно было в любое время, лучше выбрать такого мобильного оператора и такой тариф, с которым
              можно скачивать на телефон большие объёмы данных.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary aria-controls="panel2-content" id="panel2-header">
            <Typography>
              <b>Как включить все источники в «Местоположении»</b>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography component="h5" variant="h5" sx={{ fontSize: 16 }}>
              <b>Если у вас другое устройство</b>
            </Typography>
            <Typography component="h5" variant="h5" sx={{ fontSize: 16 }}>
              Попробуйте поискать в «Настройках» пункты «Аккумулятор» или «Батарея».
              <br />
              Что можно вбить в поиск: энергосбережение, батарея, аккумулятор, питание.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary aria-controls="panel2-content" id="panel2-header">
            <Typography>
              <b>Как обновить систему</b>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography component="h5" variant="h5" sx={{ fontSize: 16 }}>
              <b>Если у вас другое устройство</b>
            </Typography>
            <Typography component="h5" variant="h5" sx={{ fontSize: 16 }}>
              Попробуйте поискать в «Настройках» пункты «Аккумулятор» или «Батарея».
              <br />
              Что можно вбить в поиск: энергосбережение, батарея, аккумулятор, питание.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary aria-controls="panel2-content" id="panel2-header">
            <Typography>
              <b>Как очистить кэш или стереть данные с приложения</b>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography component="h5" variant="h5" sx={{ fontSize: 16 }}>
              <i>
                Кэш — это копии веб-страниц, картинок, видеороликов и других файлов в приложениях. Его нужно
                периодически чистить, чтобы освободить память и ускорить работу смартфона.
              </i>
            </Typography>
            <Typography component="h5" variant="h5" sx={{ mt: 4, fontSize: 16 }}>
              <b>Как это сделать</b>
            </Typography>
            <ul>
              <li>
                <Typography component="h5" variant="h5" sx={{ fontSize: 16 }}>
                  Откройте «Настройки», перейдите в раздел «Приложения» и выберите приложение для которого нужно
                  очистить кэш или данные (Яндекс Про).
                </Typography>
              </li>
              <li>
                <Typography component="h5" variant="h5" sx={{ mt: 1, fontSize: 16 }}>
                  Перейдите в «Хранилище» или «Память», нажмите на «Очистить кэш» и «Стереть данные» (см. фото).
                </Typography>
              </li>
            </ul>
            <Typography component="h5" variant="h5" sx={{ fontSize: 16 }}>
              <b>
                Смартфоны в целом накапливают очень много файлов. Это замедляет их работу, поэтому ошибка может быть не
                связана с переполненным кэшем именно в Яндекс Про. Советуем очистить кэш сразу во всех приложениях:
              </b>
            </Typography>
            <ul>
              <li>
                <Typography component="h5" variant="h5" sx={{ fontSize: 16 }}>
                  Откройте «Настройки» и перейдите в раздел «Хранилище и USB-накопители».
                </Typography>
              </li>
              <li>
                <Typography component="h5" variant="h5" sx={{ mt: 1, fontSize: 16 }}>
                  Откройте «Данные кэша».
                </Typography>
              </li>
              <li>
                <Typography component="h5" variant="h5" sx={{ mt: 1, fontSize: 16 }}>
                  Подтвердите удаление кэша .
                </Typography>
              </li>
            </ul>
          </AccordionDetails>
        </Accordion>
      </>
    </FaqBodyComponent>
  );
};

export const FAQFunctionsComponent = () => {
  React.useEffect(() => {
    document.title = 'Инструкции | КлинКар';
  }, []);

  return (
    <FaqBodyComponent
      header={'Зачем приложению доступ к функциям смартфона'}
      updatedAt={'04.10.2024'}
      linkNextHref={'/faq/geolocations/'}
      linkPrevHref={'/faq/settings/'}
    >
      <>
        <Typography component="h5" variant="h5" sx={{ fontSize: 16 }}>
          <b>Как найти любую настройку в телефоне</b>
        </Typography>
        <Typography component="h5" variant="h5" sx={{ fontSize: 16 }}>
          Чтобы вы могли воспользоваться всеми возможностями приложения, ему нужен доступ к некоторым функциям вашего
          смартфона. Приложение всегда уведомляет вас, когда нужно дать такой доступ. Эти уведомления от приложения
          позволяют вам всегда знать, какие данные и из какого источника будут поступать в распоряжение приложения —
          например, от камеры, модуля GPS. Вы всегда можете разрешить приложению использовать ту или иную функцию или
          запретить доступ к ней. Приложение не запрашивает доступ к вашим личным данным, таким, как ФИО, дата рождения.
          Для полноценной работы приложению нужен доступ только к функциям, которые могут понадобиться для повседневных
          действий с приложением — при выполнении перегонов или создания фотоотчетов.
        </Typography>
        <Accordion>
          <AccordionSummary aria-controls="panel1-content" id="panel1-header">
            <Typography>
              <b>Доступ к геоданным</b>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Приложение автоматически определяет ваше текущее местоположение, чтобы мы могли вас видеть на карте. Эта
              информация может поступать от модуля GPS, модуля Wi-Fi или от обоих.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary aria-controls="panel2-content" id="panel2-header">
            <Typography>
              <b>Доступ к камере</b>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Для прохождения некоторых проверок, предусмотренных в сервисе, вам нужно будет использовать камеру
              смартфона, чтобы сделать фото и отправить его нам. Это нужно для создания фотоотчетов. Приложение
              сохраняет сделанные изображения на вашем девайсе.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary aria-controls="panel2-content" id="panel2-header">
            <Typography>
              <b>Доступ к режиму энергосбережения</b>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              При низком заряде батареи смартфон может перейти в режим энергосбережения. В нём могут автоматически
              отключаться некоторые функции, которые критически важны для корректной работы приложения. Поэтому
              приложению необходимо иметь доступ к управлению энергосбережением, чтобы вы сохраняли доступ ко всем
              функциям приложения даже при низком заряде батареи.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </>
    </FaqBodyComponent>
  );
};

export const FAQGeolocationsComponent = () => {
  React.useEffect(() => {
    document.title = 'Инструкции | КлинКар';
  }, []);

  return (
    <FaqBodyComponent
      header={'Как улучшить точность геолокации'}
      updatedAt={'04.10.2024'}
      linkPrevHref={'/faq/functions/'}
    >
      <>
        <Typography component="h5" variant="h5" sx={{ fontSize: 16 }}>
          <b>Как найти любую настройку в телефоне</b>
        </Typography>
        <Typography component="h5" variant="h5" sx={{ fontSize: 16 }}>
          Если выключить ограничение запросов Wi-Fi в настройках смартфона, ваше местоположение будет определяться
          точнее.
        </Typography>
        <Typography component="h5" variant="h5" sx={{ fontSize: 16 }}>
          <b>Смартфоны с хорошей геолокацией</b>
        </Typography>
        <Typography component="h5" variant="h5" sx={{ fontSize: 16 }}>
          Cмартфоны ниже наименее подвержены проблемам с геолокацией. Рекомендуем пользоваться ими на линии:
        </Typography>
        <Typography component="h5" variant="h5" sx={{ fontSize: 16 }}>
          <b>Samsung</b>
        </Typography>
        <ul style={{ marginTop: 0 }}>
          <li>
            <Typography component="h5" variant="h5" sx={{ fontSize: 16 }}>
              Galaxy A34 5G
            </Typography>
          </li>
          <li>
            <Typography component="h5" variant="h5" sx={{ mt: 1, fontSize: 16 }}>
              Galaxy A32
            </Typography>
          </li>
          <li>
            <Typography component="h5" variant="h5" sx={{ mt: 1, fontSize: 16 }}>
              Galaxy A22 5G
            </Typography>
          </li>
          <li>
            <Typography component="h5" variant="h5" sx={{ mt: 1, fontSize: 16 }}>
              Galaxy A22
            </Typography>
          </li>
        </ul>
        <Typography component="h5" variant="h5" sx={{ fontSize: 16 }}>
          <b>Redmi</b>
        </Typography>
        <ul style={{ marginTop: 0 }}>
          <li>
            <Typography component="h5" variant="h5" sx={{ fontSize: 16 }}>
              Note 11 Pro+ 5G
            </Typography>
          </li>
          <li>
            <Typography component="h5" variant="h5" sx={{ mt: 1, fontSize: 16 }}>
              Note 11S
            </Typography>
          </li>
          <li>
            <Typography component="h5" variant="h5" sx={{ mt: 1, fontSize: 16 }}>
              Note 10S
            </Typography>
          </li>
        </ul>
        <Typography component="h5" variant="h5" sx={{ fontSize: 16 }}>
          <b>Google</b>
        </Typography>
        <ul style={{ marginTop: 0 }}>
          <li>
            <Typography component="h5" variant="h5" sx={{ fontSize: 16 }}>
              Pixel 5
            </Typography>
          </li>
          <li>
            <Typography component="h5" variant="h5" sx={{ mt: 1, fontSize: 16 }}>
              Pixel 6
            </Typography>
          </li>
          <li>
            <Typography component="h5" variant="h5" sx={{ mt: 1, fontSize: 16 }}>
              Pixel 7
            </Typography>
          </li>
        </ul>
        <Typography component="h5" variant="h5" sx={{ fontSize: 16 }}>
          В них используются современные GPS-модули и можно <b>отключить ограничение запросов Wi-Fi.</b>
        </Typography>
        <Typography component="h5" variant="h5" sx={{ fontSize: 16 }}>
          <b>Как выключить ограничение запросов Wi-Fi</b>
        </Typography>
        <Typography component="h5" variant="h5" sx={{ fontSize: 16 }}>
          У смартфонов Apple, Huawei и Honor, а также устройств с версией Android 9 и ниже выключить это ограничение
          невозможно.
          <br />
          Для этого сначала нужно войти в режим для разработчиков — у всех производителей он включается по-разному.
          <br />
          Проблем с геолокацией должно стать меньше, но телефон может разряжаться немного быстрее — лучше иметь под
          рукой зарядку.
        </Typography>
        <Typography component="h5" variant="h5" sx={{ fontSize: 16 }}>
          <b>Инструкции</b>
        </Typography>
        <Typography component="h5" variant="h5" sx={{ fontSize: 16 }}>
          Ниже вы найдёте подробные инструкции для самых популярных брендов.
        </Typography>

        <Accordion>
          <AccordionSummary aria-controls="panel2-content" id="panel2-header">
            <Typography>
              <b>Xiaomi</b>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Да, при выключенном ограничении заряд батареи смартфона расходуется немного быстрее, но только в тех
              районах, где есть проблемы с геолокацией. Там, где определение местоположения работает хорошо, заряд
              расходуется так же, как при базовых настройках смартфона.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary aria-controls="panel2-content" id="panel2-header">
            <Typography>
              <b>Redmi</b>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Да, при выключенном ограничении заряд батареи смартфона расходуется немного быстрее, но только в тех
              районах, где есть проблемы с геолокацией. Там, где определение местоположения работает хорошо, заряд
              расходуется так же, как при базовых настройках смартфона.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary aria-controls="panel2-content" id="panel2-header">
            <Typography>
              <b>Samsung</b>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Да, при выключенном ограничении заряд батареи смартфона расходуется немного быстрее, но только в тех
              районах, где есть проблемы с геолокацией. Там, где определение местоположения работает хорошо, заряд
              расходуется так же, как при базовых настройках смартфона.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary aria-controls="panel2-content" id="panel2-header">
            <Typography>
              <b>OnePlus</b>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Да, при выключенном ограничении заряд батареи смартфона расходуется немного быстрее, но только в тех
              районах, где есть проблемы с геолокацией. Там, где определение местоположения работает хорошо, заряд
              расходуется так же, как при базовых настройках смартфона.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary aria-controls="panel2-content" id="panel2-header">
            <Typography>
              <b>Oppo</b>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Да, при выключенном ограничении заряд батареи смартфона расходуется немного быстрее, но только в тех
              районах, где есть проблемы с геолокацией. Там, где определение местоположения работает хорошо, заряд
              расходуется так же, как при базовых настройках смартфона.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary aria-controls="panel2-content" id="panel2-header">
            <Typography>
              <b>realme</b>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Да, при выключенном ограничении заряд батареи смартфона расходуется немного быстрее, но только в тех
              районах, где есть проблемы с геолокацией. Там, где определение местоположения работает хорошо, заряд
              расходуется так же, как при базовых настройках смартфона.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary aria-controls="panel2-content" id="panel2-header">
            <Typography>
              <b>POCO</b>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Да, при выключенном ограничении заряд батареи смартфона расходуется немного быстрее, но только в тех
              районах, где есть проблемы с геолокацией. Там, где определение местоположения работает хорошо, заряд
              расходуется так же, как при базовых настройках смартфона.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Typography component="h5" variant="h5" sx={{ fontSize: 16 }}>
          <b>Вопросы и ответы</b>
        </Typography>
        <Typography component="h5" variant="h5" sx={{ fontSize: 16 }}>
          Разбираем частые причины, по которым водители не хотят отключать ограничение запросов Wi-Fi.
        </Typography>
        <Accordion>
          <AccordionSummary aria-controls="panel2-content" id="panel2-header">
            <Typography>
              <b>После отключения ограничения запросов Wi-Fi батарея разряжается быстрее</b>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Да, при выключенном ограничении заряд батареи смартфона расходуется немного быстрее, но только в тех
              районах, где есть проблемы с геолокацией. Там, где определение местоположения работает хорошо, заряд
              расходуется так же, как при базовых настройках смартфона.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary aria-controls="panel2-content" id="panel2-header">
            <Typography>
              <b>
                Я не хочу менять настройки для разработчиков — не зря производители смартфона спрятали их так далеко
              </b>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Понимаем — это может выглядеть сложно. По нашим данным, отключение ограничения запросов Wi-Fi
              действительно улучшает геолокацию и снижает количество отмен со стороны пользователей.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary aria-controls="panel2-content" id="panel2-header">
            <Typography>
              <b>Не хочу включать настройки для разработчиков — боюсь, что это даст доступ к телефону третьим лицам</b>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              В этих настройках нет ничего секретного — они спрятаны, потому что нужны редко и не всем пользователям. Мы
              рекомендуем поменять только одну настройку, которая не влияет на доступ к вашим данным.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary aria-controls="panel2-content" id="panel2-header">
            <Typography>
              <b>При изменении настроек смартфон запрашивает код доступа, не хочу его вводить</b>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Некоторые производители просят ввести ваш код разблокировки, чтобы удостовериться, что это вы вносите
              изменения.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </>
    </FaqBodyComponent>
  );
};
