// unauthorized-component
export enum UnauthorizedScreenTypes {
  WELCOME_SCREEN = 'welcome',
  SIGNIN_SCREEN = 'signin',
  SIGNUP_SCREEN = 'signup',
  RESET_PASSWORD_SCREEN = 'reset_password',
  RESET_PASSWORD_CONFIRM_SCREEN = 'reset_password_confirm',
  PRIVACY_POLICY_SCREEN = 'privacy_policy',
  VERIFY_EMAIL_SCREEN = 'verify_email',
  RESEND_EMAIl_SCREEN = 'resend_email',
  PRIVACY_POLICY_EN_SCREEN = 'privacy_policy_en',
  ACCOUNT_DELETE_SCREEN = 'account_delete',
  FAQ_SCREEN = 'faq_screen',
  FAQ_DEVICES_SCREEN = 'faq_devices_screen',
  FAQ_SETTINGS_SCREEN = 'faq_settings_screen',
  FAQ_FUNCTIONS_SCREEN = 'faq_functions_screen',
  FAQ_GEOLOCATIONS_SCREEN = 'faq_geolocations_screen',
}

export interface ErrorWithData {
  status: number;
  data: {
    first_name?: string;
    last_name?: string;
    email?: string;
    phone?: string;
    city?: string;
    admin_type?: string;
    password1?: string;
    password2?: string;
    new_password1?: string;
    new_password2?: string;
    token: string;

    detail?: string;
    non_field_errors?: string;
    message?: string;
  };
}

export type ErrorWithDetail = {
  status: number;
  data: {
    detail: string;
    non_field_errors: string;
    new_password1: string;
    new_password2: string;
  };
};

// Входные типы
export interface ISigninInputs {
  email: string;
  password: string;
}

export interface IMiddleNameInputs {
  middle_name: string;
}

export interface ISignupInputs {
  first_name: string;
  last_name: string;
  middle_name: string;
  email: string;
  phone: string;
  city: string;
  admin_type: string;
  password1: string;
  password2: string;
}

export interface IEmailData {
  email: string;
}

export interface IEmailConfirmData {
  key: string;
}

export interface IResetPasswordData {
  email: string;
}

export interface IResetPasswordConfirm {
  uid: string;
  token: string;
  new_password1: string;
  new_password2: string;
}

export interface IChangePasswordData {
  old_password: string;
  new_password1: string;
  new_password2: string;
}

export interface ITokenVerify {
  token: string;
}

export interface IResponseRefresh {
  access_expiration: string;
  access: string;
}

export interface IImageUpload {
  apiLink: string;
  formData: FormData;
}

export interface ITaskCreate {
  carsharing: string;
  type: string;
  car_model?: string;
  car_plate?: string;
  transit_season_tire_location?: string;
  distance?: string;
  mkad_bool?: string;
  kilometers_km?: string;
  service_bool?: string;
  wash_bool?: string;
  help_bool?: string;
  help_user?: string;
  radius?: string;
  receiving_area?: string;
  wash?: string;
  choose_service?: string;
  category_service?: string;
  description?: string;
  vin_number?: string;
  waybill?: string;
  destination?: string;
  departure?: string;
  destination_address?: string;
  category?: string;
}

// Ответные типы
export interface IResponseLoginData {
  access: string | null;
  refresh: string | null;
  isAuthenticated: boolean;
}

export interface IResponseDetail {
  detail: string;
}

type TCity = {
  id: string;
  name: string;
};

export interface IResponseCities {
  results: TCity[];
}

export interface IResponseProfile {
  first_name: string;
  last_name: string;
  middle_name: string;
  email: string;
  phone: string;
  city: string;
  type: [];
}

export interface IResponseCount {
  count: string;
}

export interface IResponseFinance {
  finances_day: string;
  finances_month_first: string;
  finances_month_second: string;
}

export type TTask = {
  id: string;
  car_model: string;
  car_plate: string;
  carsharing:
    | 'Делимобиль'
    | 'СитиДрайв'
    | 'RentCars'
    | 'СберАвтоподписка'
    | 'Инвитро'
    | 'Грузовичкоф'
    | 'ТестТорг'
    | 'ГАЗ'
    | 'Яндекс';
  created_at: string;
  created_by: string;
  finished_at: string;
  type: string;
  type_display: string;
  vin_number: string;
};

export interface IOpenTask extends TTask {
  photobeforetransit_set: TPhoto[];
  photoaftertransit_set: TPhoto[];
  photobeforewash_set: TPhoto[];
  photoafterwash_set: TPhoto[];
  photobeforecleaning_set: TPhoto[];
  photoaftercleaning_set: TPhoto[];
  photobeforemileage_set: TPhoto[];
  photoaftermileage_set: TPhoto[];
  photobeforetire_set: TPhoto[];
  photoaftertire_set: TPhoto[];
  photototal_set: TPhoto[];
  photodamage_set: TPhoto[];
  photonavigator_set: TPhoto[];
  antifreeze_liquid_liters_new: string;
  transit_season_tire_before: string;
  transit_season_tire_after: string;
  mileage_before_task: number;
  mileage_after_task: number;
  car_condition: string;
  car_condition_comment: string;
}

export type TPhoto = {
  id: number;
  image: string;
  filename: string;
};

export interface IResponseTasks {
  count: number;
  next: string;
  previous: string;
  results: TTask[];
}

type TCarsharing = {
  id: string;
  name: string;
};

export interface IResponseCarsharingList {
  results: TCarsharing[];
}

export interface IResponseTaskTypesList {
  name: string;
  value: string;
}

type TResponseCarsByCarsharingList = {
  name: string;
};

export interface IDataPostDelete {
  ids: string[];
  apiLink: string;
}

export interface IResponseCarsByCarsharingList {
  results: TResponseCarsByCarsharingList[];
}

type TResponseField = {
  name: string;
  value: string;
};

type TWash = {
  id: string;
  address: string;
};

type THelpList = {
  id: string;
  first_name: string;
  last_name: string;
};

export interface IResponseFieldListToDrawer {
  id?: boolean;
  car_model?: boolean;
  car_plate?: boolean;
  carsharing?: boolean;
  created_at?: boolean;
  can_delete?: boolean;
  type_display?: boolean;
  distance_display?: boolean;
  help_user?: boolean;
  radius_display?: boolean;

  category?: boolean;
  category_service?: boolean;
  choose_service?: boolean;
  departure?: boolean;
  description?: boolean;
  destination?: boolean;
  destination_address?: boolean;
  finished_at?: boolean;
  fuel_bool?: boolean;
  full_name?: boolean;
  kilometers_km?: boolean;
  mileage_after_task?: boolean;
  mileage_before_task?: boolean;
  mileage_task?: boolean;
  mkad_bool?: boolean;
  radius?: boolean;
  service_bool?: boolean;
  status?: boolean;
  tire_fitting_option?: boolean;
  type?: boolean;
  vin_number?: boolean;
  wash?: boolean;
  wash_bool?: boolean;
  wash_option?: boolean;
  waybill?: boolean;
  has_antifreeze_liquid?: boolean;
  antifreeze_liquid_liters_first?: boolean;
  antifreeze_liquid_liters_last?: boolean;
  antifreeze_liquid_liters_new?: boolean;
}

export interface IResponseFieldListByCarsharingAndTypes {
  car_plate?: boolean;
  car_model?: boolean;
  car_plate_autocomplete?: string[];
  distance?: TResponseField[];
  mkad_bool?: boolean;
  help_list?: THelpList[];
  wash_address?: TWash[];
  choose_service?: boolean;
  category_service?: boolean;
  choose_service_list?: boolean;
  category_service_list?: boolean;
  km?: boolean;
  vin_number?: boolean;
  radius?: TResponseField[];
  description?: boolean;
  locations?: TResponseField[];
  category?: TResponseField[];
  receiving_areas?: TResponseField[];
}

type TStage = {
  name: string;
  min_photo: number;
  max_photo: number;
  api_link: string;
  serializer_name:
    | 'photobeforetransit_set'
    | 'photoaftertransit_set'
    | 'photobeforewash_set'
    | 'photoafterwash_set'
    | 'photobeforecleaning_set'
    | 'photoaftercleaning_set'
    | 'photobeforemileage_set'
    | 'photoaftermileage_set'
    | 'photobeforetire_set'
    | 'photoaftertire_set'
    | 'photototal_set'
    | 'photodamage_set'
    | 'photonavigator_set'
    | 'mileage_before_task'
    | 'mileage_after_task'
    | 'antifreeze_liquid_liters_new'
    | 'transit_season_tire_before'
    | 'transit_season_tire_after'
    | 'car_condition';
  image_name: string;
  require: string;
  photo_stage: string;
  input_stage: string;
  autocomplete_stage: string;
  autocomplete_options: [];
  select_stage: string;
  select_options: [];
  quiz_stage: string;
};

export interface IResponseNavigation {
  name: string;
  stages: TStage[];
}
