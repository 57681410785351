import * as React from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Container,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { ITaskCreate, TTask } from '../types';
import { LoadingButton } from '@mui/lab';
import { carPlateValidation, nonRequiredValidation, requiredValidation, vinValidation } from '../form-validation';
import { useNavigate } from 'react-router-dom';
import {
  useGetCarsharingListQuery,
  useLazyGetCarsByCarsharingQuery,
  useLazyGetTypesByCarsharingQuery,
  useLazyGetFieldListByCarsharingAndTypesQuery,
  usePostCreateTaskMutation,
} from '../../redux/services/tasks';
import { LuRefreshCcw } from 'react-icons/lu';
import { toast } from 'react-toastify';

const CreateTaskComponent: React.FC = () => {
  const carsharingList = useGetCarsharingListQuery();
  const [createTask, createTaskResult] = usePostCreateTaskMutation();
  const [carsListByCarsharing, carsListByCarsharingResult] = useLazyGetCarsByCarsharingQuery();
  const [typesListByCarsharing, typesListByCarsharingResult] = useLazyGetTypesByCarsharingQuery();
  const [fieldListByCarsharingAndTypes, fieldListByCarsharingAndTypesResult] =
    useLazyGetFieldListByCarsharingAndTypesQuery();
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    watch,
    resetField,
    setValue,
    reset,
    unregister,
    formState: { errors },
  } = useForm<ITaskCreate>({
    defaultValues: {
      carsharing: '',
      type: '',
      car_model: '',
      car_plate: '',
      transit_season_tire_location: '',
      distance: '',
      mkad_bool: 'Нет',
      kilometers_km: '0',
      service_bool: 'Нет',
      wash_bool: 'Нет',
      help_bool: 'Нет',
      help_user: '',
      radius: '',
      wash: '',
      choose_service: '',
      category_service: '',
      description: '',
      vin_number: '',
      waybill: '',
      destination: '',
      departure: '',
      destination_address: '',
      receiving_area: '',
    },
    shouldUnregister: true,
  });

  React.useEffect(() => {
    document.title = 'Создать задачу | КлинКар';
    reset();
    carsListByCarsharing('');
    typesListByCarsharing('');
    fieldListByCarsharingAndTypes({ carsharing: '', task_type: '' });
    unregister();
  }, []);

  React.useEffect(() => {
    // Процедура для вставки в поле Компания дефолтного значения при возвращении одного единственного значения с backend
    if (carsharingList) {
      setValue('carsharing', carsharingList.data?.results.length === 1 ? carsharingList.data?.results[0].name : '');
    }
  }, [carsharingList]);

  React.useEffect(() => {
    // Процедура для отслеживания изменений в поле Компания и вывод типов, марок автомобилей и полей для заполнения
    if (watch('carsharing')) {
      carsListByCarsharing(watch('carsharing'));
      typesListByCarsharing(watch('carsharing'));
      fieldListByCarsharingAndTypes({ carsharing: watch('carsharing'), task_type: '' });
    }
  }, [watch('carsharing')]);

  const onSubmit: SubmitHandler<ITaskCreate> = (data) => {
    createTaskQuery(data);
  };

  const createTaskQuery = async (data: ITaskCreate) => {
    toast
      .promise(createTask(data).unwrap(), {
        success: 'Задача успешно создана 👌',
        error: 'Ошибка при создании задачи 🤯',
      })
      .then((payload) => {
        navigate(`/task/${payload.id}`);
      });
  };

  return (
    <Container component="main" maxWidth="xs" sx={{ flexGrow: 1, pb: 8 }}>
      <Box sx={{ marginTop: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
          <Controller
            name="carsharing"
            control={control}
            rules={requiredValidation}
            render={({ field }) => (
              <FormControl fullWidth size="small" disabled={carsharingList.isFetching} sx={{ mt: 0, mb: 1 }}>
                <InputLabel id="id-company">Компания</InputLabel>
                <Select
                  data-cy="select-company"
                  label="Компания"
                  value={field.value}
                  error={!!errors.carsharing?.message}
                  onChange={(event: SelectChangeEvent) => {
                    field.onChange(event.target.value);
                    resetField('type');
                    resetField('car_model');
                    resetField('vin_number');
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        sx={{ mr: 1 }}
                        disabled={carsharingList.isFetching}
                        onClick={() => {
                          carsharingList.refetch();
                          resetField(field.name);
                          resetField('type');
                          resetField('distance');
                          resetField('car_model');
                          resetField('vin_number');
                          carsListByCarsharing(watch('carsharing'));
                          typesListByCarsharing(watch('carsharing'));
                          fieldListByCarsharingAndTypes({ carsharing: '', task_type: '' });
                          unregister();
                        }}
                      >
                        <LuRefreshCcw size={18} />
                      </IconButton>
                    </InputAdornment>
                  }
                >
                  {carsharingList.data?.results.map((item) => (
                    <MenuItem key={item.id} value={item.name}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText error={!!errors.carsharing?.message}>{errors.carsharing?.message}</FormHelperText>
              </FormControl>
            )}
          />
          <Controller
            name="type"
            control={control}
            rules={requiredValidation}
            render={({ field }) => (
              <FormControl
                fullWidth
                size="small"
                disabled={typesListByCarsharingResult.isFetching || carsharingList.isFetching}
                sx={{ mt: 1, mb: 1 }}
              >
                <InputLabel id="id-task_type">Тип задачи</InputLabel>
                <Select
                  data-cy="select-task-type"
                  label="Тип задачи"
                  value={field.value}
                  error={!!errors.type?.message}
                  onChange={(event: SelectChangeEvent) => {
                    field.onChange(event.target.value);
                    resetField('car_plate');
                    resetField('distance');
                    resetField('mkad_bool');
                    resetField('radius');
                    resetField('transit_season_tire_location');
                    resetField('help_user');
                    resetField('choose_service');
                    resetField('category_service');
                    resetField('wash');
                    resetField('kilometers_km');
                    resetField('description');
                    resetField('vin_number');
                    fieldListByCarsharingAndTypes({ carsharing: watch('carsharing'), task_type: watch('type') });
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        sx={{ mr: 1 }}
                        disabled={typesListByCarsharingResult.isFetching || carsharingList.isFetching}
                        onClick={() => {
                          resetField(field.name);
                          typesListByCarsharing(watch('carsharing'));
                          fieldListByCarsharingAndTypes({ carsharing: '', task_type: '' });
                          unregister();
                        }}
                      >
                        <LuRefreshCcw size={18} />
                      </IconButton>
                    </InputAdornment>
                  }
                >
                  {typesListByCarsharingResult.data?.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText error={!!errors.type?.message}>{errors.type?.message}</FormHelperText>
              </FormControl>
            )}
          />
          {/* Отображение поля "Автомобиль" по условию с backend */}
          {fieldListByCarsharingAndTypesResult.data?.car_model && (
            <Controller
              name="car_model"
              control={control}
              rules={requiredValidation}
              render={({ field }) => (
                <FormControl
                  fullWidth
                  size="small"
                  disabled={carsListByCarsharingResult.isFetching || carsharingList.isFetching}
                  sx={{ mt: 1, mb: 1 }}
                >
                  <Autocomplete
                    data-cy="select-auto"
                    id="car_model"
                    autoSelect
                    noOptionsText="Не найдено"
                    size="small"
                    fullWidth
                    value={field.value}
                    options={
                      carsListByCarsharingResult.data !== undefined
                        ? carsListByCarsharingResult.data.results.map((option) => option.name)
                        : []
                    }
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        paddingRight: '9px!important',
                      },
                    }}
                    onChange={(event, newValue) => {
                      field.onChange(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Автомобиль"
                        error={!!errors.car_model?.message}
                        InputLabelProps={{
                          sx: { color: 'rgba(0, 0, 0, 0.6)', '&.Mui-focused': { color: '#3a9561' } },
                        }}
                        sx={{
                          '& label.Mui-error': {
                            color: 'rgba(0, 0, 0, 0.6)',
                          },

                          '& label.Mui-focused': {
                            color: '#3a9561',
                          },
                        }}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              sx={{
                                '& .MuiOutlinedInput-root': {
                                  paddingRight: '9px!important',
                                },
                              }}
                            >
                              <IconButton
                                disabled={carsListByCarsharingResult.isFetching || carsharingList.isFetching}
                                onClick={() => {
                                  carsListByCarsharing(watch('carsharing'));
                                  resetField(field.name);
                                }}
                              >
                                <LuRefreshCcw size={18} />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                  <FormHelperText error={!!errors.car_model?.message}>{errors.car_model?.message}</FormHelperText>
                </FormControl>
              )}
            />
          )}
          {/* Отображение поля "Дистанция" по условию с backend */}
          {fieldListByCarsharingAndTypesResult.data?.distance && (
            <Controller
              name="distance"
              control={control}
              rules={requiredValidation}
              render={({ field }) => (
                <FormControl fullWidth size="small" sx={{ mt: 1, mb: 1 }}>
                  <InputLabel id="id-distance">Дистанция</InputLabel>
                  <Select
                    data-cy="select-distance"
                    label="Дистанция"
                    value={field.value}
                    error={!!errors.distance?.message}
                    onChange={(event: SelectChangeEvent) => {
                      field.onChange(event.target.value);
                    }}
                  >
                    {fieldListByCarsharingAndTypesResult.data?.distance!.map((item) => (
                      <MenuItem key={item.name} value={item.value}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText error={!!errors.distance?.message}>{errors.distance?.message}</FormHelperText>
                </FormControl>
              )}
            />
          )}
          {/* Отображение поля "Площадка получения" по условию с backend */}
          {fieldListByCarsharingAndTypesResult.data?.receiving_areas && (
            <Controller
              name="receiving_area"
              control={control}
              rules={requiredValidation}
              render={({ field }) => (
                <FormControl fullWidth size="small" sx={{ mt: 1, mb: 1 }}>
                  <InputLabel id="id-distance">Площадка получения</InputLabel>
                  <Select
                    data-cy="select-receiving_areas"
                    label="Площадка получения"
                    value={field.value}
                    error={!!errors.receiving_area?.message}
                    onChange={(event: SelectChangeEvent) => {
                      field.onChange(event.target.value);
                    }}
                  >
                    {fieldListByCarsharingAndTypesResult.data?.receiving_areas!.map((item) => (
                      <MenuItem key={item.name} value={item.value}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText error={!!errors.receiving_area?.message}>
                    {errors.receiving_area?.message}
                  </FormHelperText>
                </FormControl>
              )}
            />
          )}
          {/* Отображение поля "Номер VIN" по условию с backend */}
          {fieldListByCarsharingAndTypesResult.data?.vin_number && (
            <Controller
              name="vin_number"
              control={control}
              rules={vinValidation}
              render={({ field }) => (
                <TextField
                  data-cy="input-vin-number"
                  label="Номер VIN"
                  margin="normal"
                  sx={{
                    mt: 1,
                    '& label.Mui-error': {
                      color: 'rgba(0, 0, 0, 0.6)',
                    },

                    '& label.Mui-focused': {
                      color: '#3a9561',
                    },
                  }}
                  fullWidth
                  size="small"
                  onChange={(e) => {
                    field.onChange(e.target.value.toUpperCase());
                  }}
                  value={field.value}
                  error={!!errors.vin_number?.message}
                  helperText={errors.vin_number?.message}
                />
              )}
            />
          )}
          {/* Отображение поля "МКАД / КАД" по условию с backend */}
          {fieldListByCarsharingAndTypesResult.data?.mkad_bool && (
            <Controller
              name="mkad_bool"
              control={control}
              rules={nonRequiredValidation}
              render={({ field }) => (
                <FormControl fullWidth size="small" sx={{ mt: 1, mb: 1 }}>
                  <InputLabel id="id-mkad_bool">МКАД / КАД</InputLabel>
                  <Select
                    data-cy="select-mkad"
                    label="МКАД / КАД"
                    value={field.value}
                    error={!!errors.mkad_bool?.message}
                    onChange={(event: SelectChangeEvent) => {
                      field.onChange(event.target.value);
                    }}
                  >
                    <MenuItem value="Да">Да</MenuItem>
                    <MenuItem value="Нет">Нет</MenuItem>
                  </Select>
                  <FormHelperText error={!!errors.mkad_bool?.message}>{errors.mkad_bool?.message}</FormHelperText>
                </FormControl>
              )}
            />
          )}
          {/* Отображение поля "Радиус" по условию с backend */}
          {fieldListByCarsharingAndTypesResult.data?.radius && (
            <Controller
              name="radius"
              control={control}
              rules={requiredValidation}
              render={({ field }) => (
                <FormControl fullWidth size="small" sx={{ mt: 1, mb: 1 }}>
                  <InputLabel id="id-radius">Радиус</InputLabel>
                  <Select
                    data-cy="select-radius"
                    label="Радиус"
                    value={field.value}
                    error={!!errors.radius?.message}
                    onChange={(event: SelectChangeEvent) => {
                      field.onChange(event.target.value);
                    }}
                  >
                    {fieldListByCarsharingAndTypesResult.data?.radius!.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText error={!!errors.radius?.message}>{errors.radius?.message}</FormHelperText>
                </FormControl>
              )}
            />
          )}
          {/* Отображение поля "Локация" по условию с backend */}
          {fieldListByCarsharingAndTypesResult.data?.locations && (
            <Controller
              name="transit_season_tire_location"
              control={control}
              rules={requiredValidation}
              render={({ field }) => (
                <FormControl fullWidth size="small" sx={{ mt: 1, mb: 1 }}>
                  <Autocomplete
                    data-cy="select-transit_season_tire_location"
                    id="transit_season_tire_location"
                    autoSelect
                    noOptionsText="Не найдено"
                    size="small"
                    fullWidth
                    options={fieldListByCarsharingAndTypesResult.data!.locations!.map((option) => option.name)}
                    onChange={(event, newValue) => {
                      field.onChange(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Локация" error={!!errors.transit_season_tire_location?.message} />
                    )}
                  />
                  <FormHelperText error={!!errors.transit_season_tire_location?.message}>
                    {errors.transit_season_tire_location?.message}
                  </FormHelperText>
                </FormControl>
              )}
            />
          )}
          {/* Отображение поля "Помощник" по условию с backend */}
          {fieldListByCarsharingAndTypesResult.data?.help_list && (
            <Controller
              name="help_user"
              control={control}
              rules={nonRequiredValidation}
              render={({ field }) => (
                <FormControl fullWidth size="small" sx={{ mt: 1, mb: 1 }}>
                  <InputLabel id="id-help_user">Помощник</InputLabel>
                  <Select
                    data-cy="select-help-user"
                    label="Помощник"
                    value={field.value}
                    error={!!errors.help_user?.message}
                    onChange={(event: SelectChangeEvent) => {
                      field.onChange(event.target.value);
                    }}
                  >
                    {fieldListByCarsharingAndTypesResult.data?.help_list!.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.last_name} {item.first_name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText error={!!errors.help_user?.message}>{errors.help_user?.message}</FormHelperText>
                </FormControl>
              )}
            />
          )}
          {/* Отображение поля "Выберите услугу" по условию с backend */}
          {fieldListByCarsharingAndTypesResult.data?.category_service && (
            <Controller
              name="category_service"
              control={control}
              rules={requiredValidation}
              render={({ field }) => (
                <FormControl fullWidth size="small" sx={{ mt: 1, mb: 1 }}>
                  <InputLabel id="id-category_service">Выберите услугу</InputLabel>
                  <Select
                    label="Выберите услугу"
                    value={field.value}
                    error={!!errors.category_service?.message}
                    onChange={(event: SelectChangeEvent) => {
                      field.onChange(event.target.value);
                    }}
                  >
                    <MenuItem value={'sale'}>{'Предпродажная подготовка'}</MenuItem>
                    <MenuItem value={'rent'}>{'Длительная аренда'}</MenuItem>
                  </Select>
                  <FormHelperText error={!!errors.category_service?.message}>
                    {errors.category_service?.message}
                  </FormHelperText>
                </FormControl>
              )}
            />
          )}
          {/* Отображение поля "Категория услуги" по условию с backend */}
          {fieldListByCarsharingAndTypesResult.data?.choose_service && (
            <Controller
              name="choose_service"
              control={control}
              rules={requiredValidation}
              render={({ field }) => (
                <FormControl fullWidth size="small" sx={{ mt: 1, mb: 1 }}>
                  <InputLabel id="id-choose_service">Категория услуги</InputLabel>
                  <Select
                    label="Категория услуги"
                    value={field.value}
                    error={!!errors.choose_service?.message}
                    onChange={(event: SelectChangeEvent) => {
                      field.onChange(event.target.value);
                    }}
                  >
                    <MenuItem value={'with_polish'}>{'С полировкой'}</MenuItem>
                    <MenuItem value={'without_polish'}>{'Без полировки'}</MenuItem>
                  </Select>
                  <FormHelperText error={!!errors.choose_service?.message}>
                    {errors.choose_service?.message}
                  </FormHelperText>
                </FormControl>
              )}
            />
          )}
          {/* Отображение поля "Мойки" по условию с backend */}
          {fieldListByCarsharingAndTypesResult.data?.wash_address && (
            <Controller
              name="wash"
              control={control}
              rules={requiredValidation}
              render={({ field }) => (
                <FormControl fullWidth size="small" sx={{ mt: 1, mb: 1 }}>
                  <InputLabel id="id-wash">Мойка</InputLabel>
                  <Select
                    label="Мойка"
                    value={field.value}
                    error={!!errors.wash?.message}
                    onChange={(event: SelectChangeEvent) => {
                      field.onChange(event.target.value);
                    }}
                  >
                    {fieldListByCarsharingAndTypesResult.data?.wash_address!.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.address}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText error={!!errors.wash?.message}>{errors.wash?.message}</FormHelperText>
                </FormControl>
              )}
            />
          )}
          {/* Отображение поля "Километраж" по условию с backend */}
          {fieldListByCarsharingAndTypesResult.data?.km && (
            <Controller
              name="kilometers_km"
              control={control}
              rules={nonRequiredValidation}
              render={({ field }) => (
                <TextField
                  label="Километраж"
                  margin="normal"
                  sx={{ mt: 1 }}
                  fullWidth
                  size="small"
                  onChange={(e) => {
                    field.onChange(e);
                  }}
                  value={field.value}
                  error={!!errors.kilometers_km?.message}
                  helperText={errors.kilometers_km?.message}
                />
              )}
            />
          )}
          {/* Отображение поля "Описание" по условию с backend */}
          {fieldListByCarsharingAndTypesResult.data?.description && (
            <Controller
              name="description"
              control={control}
              rules={requiredValidation}
              render={({ field }) => (
                <TextField
                  label="Описание"
                  margin="normal"
                  rows={4}
                  multiline={true}
                  sx={{ mt: 1 }}
                  fullWidth
                  size="small"
                  onChange={(e) => {
                    field.onChange(e);
                  }}
                  value={field.value}
                  error={!!errors.description?.message}
                  helperText={errors.description?.message}
                />
              )}
            />
          )}
          {/* Отображение поля "Номер автомобиля" по условию с backend */}
          {fieldListByCarsharingAndTypesResult.data?.car_plate && (
            <Controller
              name="car_plate"
              control={control}
              rules={carPlateValidation}
              render={({ field }) => (
                <TextField
                  data-cy="input-car-plate"
                  label="Номер автомобиля"
                  margin="normal"
                  sx={{ mt: 1 }}
                  fullWidth
                  size="small"
                  onChange={(e) => {
                    field.onChange(e.target.value.toUpperCase());
                  }}
                  value={field.value}
                  error={!!errors.car_plate?.message}
                  helperText={errors.car_plate?.message}
                />
              )}
            />
          )}
          {/* Отображение поля "Номер автомобиля" по условию с backend с автодополнением */}
          {fieldListByCarsharingAndTypesResult.data?.car_plate_autocomplete && (
            <Controller
              name="car_plate"
              control={control}
              rules={requiredValidation}
              render={({ field }) => (
                <FormControl fullWidth size="small" sx={{ mt: 1, mb: 1 }}>
                  <InputLabel id="id-distance">Номер автомобиля</InputLabel>
                  <Select
                    data-cy="select-car_plate"
                    label="Номер автомобиля"
                    value={field.value}
                    error={!!errors.car_plate?.message}
                    onChange={(event: SelectChangeEvent) => {
                      field.onChange(event.target.value);
                    }}
                  >
                    {fieldListByCarsharingAndTypesResult.data?.car_plate_autocomplete!.map((item) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText error={!!errors.car_plate?.message}>{errors.car_plate?.message}</FormHelperText>
                </FormControl>
              )}
            />
          )}
          <LoadingButton
            type="submit"
            loading={createTaskResult.isLoading}
            fullWidth
            data-cy="create-task-submit"
            variant="contained"
            sx={{ mt: 2, mb: 2 }}
            size="large"
          >
            Создать задачу
          </LoadingButton>
          <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Button
              onClick={(e) => {
                e.preventDefault();
                navigate(-1);
              }}
            >
              Назад
            </Button>
          </Box>
          <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Button
              onClick={(e) => {
                reset();
                e.preventDefault();
                carsListByCarsharing(watch('carsharing'));
                typesListByCarsharing(watch('carsharing'));
                fieldListByCarsharingAndTypes({ carsharing: '', task_type: '' });
                unregister();
              }}
            >
              Очистить форму
            </Button>
          </Box>
        </form>
      </Box>
    </Container>
  );
};

export default CreateTaskComponent;
