import React from 'react';

import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

interface IFaqBlockComponent {
  header: string;
  body: string;
  linkHref: string;
}

const FaqBlockComponent: React.FC<IFaqBlockComponent> = ({ header, body, linkHref }) => {
  return (
    <Link to={linkHref} style={{ textDecoration: 'none' }}>
      <Box sx={{ background: '#dbf0e0', p: 4, borderRadius: 6, color: 'black' }}>
        <Typography component="h5" variant="h5" sx={{ mt: 1, fontSize: 20, fontWeight: 700 }}>
          {header}
        </Typography>
        <Typography component="h5" variant="h5" sx={{ mt: 1, fontSize: 14 }}>
          {body}
        </Typography>
        <Typography component="h5" variant="h5" sx={{ mt: 1, fontSize: 18, pt: 3, fontWeight: 700 }}>
          Читать
        </Typography>
      </Box>
    </Link>
  );
};

export default FaqBlockComponent;
