import * as React from 'react';
import {
  Breadcrumbs,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import {
  cityValidation,
  emailValidation,
  namesValidation,
  passwordValidation,
  phoneValidation,
  typeValidation,
} from '../form-validation';
import { ISignupInputs } from '../types';
import { UI_URLS } from '../../config';
import { useCityQuery, useSignupMutation } from '../../redux/services/auth';
import LoadingButton from '@mui/lab/LoadingButton';
import { toast } from 'react-toastify';
import { isErrorWithData } from '../is-error-with-message';
import { LuEye, LuEyeOff } from 'react-icons/lu';

const SignupComponent: React.FC = () => {
  const city = useCityQuery();
  const navigate = useNavigate();
  const [signup, signupResult] = useSignupMutation();
  const [showPassword1, setShowPassword1] = React.useState(false);
  const [showPassword2, setShowPassword2] = React.useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ISignupInputs>({
    defaultValues: {
      first_name: '',
      last_name: '',
      middle_name: '',
      email: '',
      phone: '79',
      city: '',
      admin_type: '',
      password1: '',
      password2: '',
    },
  });

  React.useEffect(() => {
    document.title = 'Регистрация | КлинКар';
  }, []);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const onSubmit: SubmitHandler<ISignupInputs> = (data) => {
    signupQuery(data);
  };

  const signupQuery = async (data: ISignupInputs) => {
    try {
      await signup(data)
        .unwrap()
        .then((response) => {
          toast.success(response.detail);
        });
      navigate(`/signin/`);
    } catch (err) {
      const error = isErrorWithData(err);

      if (error) {
        if (err.data.first_name) {
          toast.error(err.data.first_name.toString());
        } else if (err.data.last_name) {
          toast.error(err.data.last_name.toString());
        } else if (err.data.last_name) {
          toast.error(err.data.last_name.toString());
        } else if (err.data.email) {
          toast.error(err.data.email.toString());
        } else if (err.data.phone) {
          toast.error(err.data.phone.toString());
        } else if (err.data.city) {
          toast.error(err.data.city.toString());
        } else if (err.data.admin_type) {
          toast.error(err.data.admin_type.toString());
        } else if (err.data.password1) {
          toast.error(err.data.password1.toString());
        } else if (err.data.password2) {
          toast.error(err.data.password2.toString());
        } else if (err.data.non_field_errors) {
          toast.error(err.data.non_field_errors.toString());
        } else if (err.data.detail) {
          toast.error(err.data.detail.toString());
        } else {
          toast.error('Произошла ошибка');
        }
      } else {
        toast.error('Неизвестная ошибка');
      }
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Breadcrumbs aria-label="breadcrumb">
          <Link component={RouterLink} underline="hover" color="inherit" to={UI_URLS.WELCOME_LINK}>
            Главная
          </Link>
          <Typography color="text.primary">Регистрация</Typography>
        </Breadcrumbs>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="first_name"
            control={control}
            rules={namesValidation}
            render={({ field }) => (
              <TextField
                label="Имя"
                margin="normal"
                fullWidth
                size="small"
                autoComplete="given-name"
                onChange={(e) => {
                  field.onChange(e);
                }}
                value={field.value}
                error={!!errors.first_name?.message}
                helperText={errors.first_name?.message}
              />
            )}
          />
          <Controller
            name="last_name"
            control={control}
            rules={namesValidation}
            render={({ field }) => (
              <TextField
                label="Фамилия"
                margin="normal"
                fullWidth
                size="small"
                autoComplete="family-name"
                onChange={(e) => {
                  field.onChange(e);
                }}
                value={field.value}
                error={!!errors.last_name?.message}
                helperText={errors.last_name?.message}
              />
            )}
          />
          <Controller
            name="middle_name"
            control={control}
            rules={namesValidation}
            render={({ field }) => (
              <TextField
                label="Отчество"
                margin="normal"
                fullWidth
                size="small"
                autoComplete="middle-name"
                onChange={(e) => {
                  field.onChange(e);
                }}
                value={field.value}
                error={!!errors.middle_name?.message}
                helperText={errors.middle_name?.message}
              />
            )}
          />
          <Controller
            name="email"
            control={control}
            rules={emailValidation}
            render={({ field }) => (
              <TextField
                label="Почта"
                margin="normal"
                fullWidth
                size="small"
                autoComplete="email"
                onChange={(e) => {
                  field.onChange(e);
                }}
                value={field.value}
                error={!!errors.email?.message}
                helperText={errors.email?.message}
              />
            )}
          />
          <Controller
            name="phone"
            control={control}
            rules={phoneValidation}
            render={({ field }) => (
              <TextField
                label="Номер телефона"
                margin="normal"
                inputProps={{ maxLength: 11 }}
                fullWidth
                size="small"
                autoComplete="phone"
                onChange={(e) => {
                  field.onChange(e);
                }}
                value={field.value}
                error={!!errors.phone?.message}
                helperText={errors.phone?.message}
              />
            )}
          />
          <Controller
            name="city"
            control={control}
            rules={cityValidation}
            render={({ field }) => (
              <FormControl fullWidth size="small" disabled={city.isLoading} sx={{ mt: 2, mb: 1 }}>
                <InputLabel id="id-city">Город</InputLabel>
                <Select
                  label="Город"
                  value={field.value}
                  error={!!errors.city?.message}
                  onChange={(event: SelectChangeEvent) => {
                    field.onChange(event.target.value);
                  }}
                >
                  {city.data?.results.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText error={!!errors.city?.message}>{errors.city?.message}</FormHelperText>
              </FormControl>
            )}
          />
          <Controller
            name="admin_type"
            control={control}
            rules={typeValidation}
            render={({ field }) => (
              <TextField
                label="Тип пользователя"
                margin="normal"
                fullWidth
                size="small"
                onChange={(e) => {
                  field.onChange(e);
                }}
                value={field.value}
                error={!!errors.admin_type?.message}
                helperText={errors.admin_type?.message}
              />
            )}
          />
          <Controller
            name="password1"
            control={control}
            rules={passwordValidation}
            render={({ field }) => (
              <TextField
                label="Пароль"
                margin="normal"
                fullWidth
                size="small"
                type={showPassword1 ? 'text' : 'password'}
                autoComplete="current-password"
                onChange={(e) => {
                  field.onChange(e);
                }}
                value={field.value}
                error={!!errors.password1?.message}
                helperText={errors.password1?.message}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword1((prevValue) => !prevValue)}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword1 ? <LuEyeOff color="" /> : <LuEye />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
          <Controller
            name="password2"
            control={control}
            rules={passwordValidation}
            render={({ field }) => (
              <TextField
                label="Повторите пароль"
                margin="normal"
                fullWidth
                size="small"
                type={showPassword2 ? 'text' : 'password'}
                autoComplete="current-password"
                onChange={(e) => {
                  field.onChange(e);
                }}
                value={field.value}
                error={!!errors.password2?.message}
                helperText={errors.password2?.message}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword2((prevValue) => !prevValue)}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword2 ? <LuEyeOff color="" /> : <LuEye />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
          <Typography textAlign={'center'} sx={{ mt: 1 }}>
            Нажимая кнопку ниже, Вы соглашаетесь на обработку&nbsp;
            <Link component={RouterLink} underline="hover" color="primary" to={UI_URLS.POLICY_LINK}>
              персональных данных
            </Link>
          </Typography>
          <LoadingButton
            type="submit"
            loading={signupResult.isLoading}
            fullWidth
            variant="contained"
            sx={{ mt: 2, mb: 2 }}
            size="large"
          >
            Зарегистрироваться
          </LoadingButton>
        </form>
      </Box>
    </Container>
  );
};

export default SignupComponent;
