import * as React from 'react';

import { DeleteAccountComponent } from './delete_account';
import PrivacyPolicyComponent from './privacy_policy-component';
import PrivacyPolicyEnComponent from './privacy_policy-component.google';
import { ResendEmailComponent } from './resend_email-component';
import { ResetPasswordComponent } from './reset_password-component';
import ResetPasswordConfirmComponent from './reset_password_confirm-component';
import SigninComponent from './signin-component';
import SignupComponent from './signup-component';
import VerifyEmailComponent from './verify_email-component';
import WelcomeComponent from './welcome-component';
import {
  FAQComponent,
  FAQDevicesComponent,
  FAQFunctionsComponent,
  FAQGeolocationsComponent,
  FAQSettingsComponent,
} from '../faq-screen';
import { UnauthorizedScreenTypes } from '../types';

const UnauthorizedComponent: React.FC<{ type: string }> = ({ type }) => {
  return (
    <>
      {type === UnauthorizedScreenTypes.WELCOME_SCREEN && <WelcomeComponent />}
      {type === UnauthorizedScreenTypes.SIGNIN_SCREEN && <SigninComponent />}
      {type === UnauthorizedScreenTypes.SIGNUP_SCREEN && <SignupComponent />}
      {type === UnauthorizedScreenTypes.RESET_PASSWORD_SCREEN && <ResetPasswordComponent />}
      {type === UnauthorizedScreenTypes.RESET_PASSWORD_CONFIRM_SCREEN && <ResetPasswordConfirmComponent />}
      {type === UnauthorizedScreenTypes.PRIVACY_POLICY_SCREEN && <PrivacyPolicyComponent />}
      {type === UnauthorizedScreenTypes.VERIFY_EMAIL_SCREEN && <VerifyEmailComponent />}
      {type === UnauthorizedScreenTypes.RESEND_EMAIl_SCREEN && <ResendEmailComponent />}
      {type === UnauthorizedScreenTypes.PRIVACY_POLICY_EN_SCREEN && <PrivacyPolicyEnComponent />}
      {type === UnauthorizedScreenTypes.ACCOUNT_DELETE_SCREEN && <DeleteAccountComponent />}
      {type === UnauthorizedScreenTypes.FAQ_SCREEN && <FAQComponent />}
      {type === UnauthorizedScreenTypes.FAQ_DEVICES_SCREEN && <FAQDevicesComponent />}
      {type === UnauthorizedScreenTypes.FAQ_SETTINGS_SCREEN && <FAQSettingsComponent />}
      {type === UnauthorizedScreenTypes.FAQ_FUNCTIONS_SCREEN && <FAQFunctionsComponent />}
      {type === UnauthorizedScreenTypes.FAQ_GEOLOCATIONS_SCREEN && <FAQGeolocationsComponent />}
    </>
  );
};

export default UnauthorizedComponent;
